import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Header/Header";
import "./Home.scss";
import Button from "../../Components/Button/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faUserGroup, faBed, faChevronRight, faCarOn, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS, apiCall } from '../../Lib/App';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import HomeImg from "../../Assets/home.jpg"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import placeholder from "../../Assets/placeholder.png";
import video1 from "../../Assets/video8.mp4";
import homeFav from "../../Assets/01-04.jpg"


const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const testimonialSettings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
  };

  useEffect(() => {
    fetchVillas();
  }, []);

  const [villas, setVillas] = useState([]);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");




  const fetchVillas = async () => {
    try {
      const endpoint = ENDPOINTS.getAllVillas;
      const response = await apiCall(endpoint);
      if (response) {
        const parsedVillas = response.villas.map(villa => ({
          ...villa,

        }));
        setVillas(parsedVillas);
      } else {
        setVillas([]);
      }
    } catch (error) {
      console.error("Failed to fetch villas", error);
      setVillas([]);
    }
  }


  const handleCheckInDateChange = (e) => {
    setCheckInDate(e.target.value);
  };

  const handleCheckOutDateChange = (e) => {
    setCheckOutDate(e.target.value);
  };

  const checkAvailability = async () => {
    if (!checkInDate || !checkOutDate) {
      alert('Please select both check-in and check-out dates.');
      return;
    }

    let availableVillas = [];

    for (let villa of villas) {
      const endpoint = buildEndpoint(ENDPOINTS.getBookedDatesCheckavailability, villa.villa_id);
      const response = await apiCall(endpoint);
      const bookedDates = response.bookedDates;
      const isUnavailable = bookedDates.some(({ start_date, end_date }) => {
        return (
          (checkInDate >= start_date && checkInDate <= end_date) ||
          (checkOutDate >= start_date && checkOutDate <= end_date) ||
          (checkInDate <= start_date && checkOutDate >= end_date)
        );
      });

      if (!isUnavailable) {
        availableVillas.push(villa);
      }
    }

    navigate('/rooms', { state: { filteredVillas: availableVillas } });
  };


  const buildEndpoint = (endpoint, id) => {
    const clonedEndpoint = { ...endpoint };
    clonedEndpoint.url += `/${id}`;
    return clonedEndpoint;
  };

  const ArrowLeft = (props) => (
    <button {...props} className={"prev-arrow"}>
      {" "}
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );
  const ArrowRight = (props) => (
    <button {...props} className={"next-arrow"}>
      {" "}
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  const handleDirection = () => {
    const mapsUrl = "https://www.google.com/maps/place/Bujtinat+LISA/@42.740444,20.0740247,17z/data=!3m1!4b1!4m5!3m4!1s0x1352f117bfaf4eaf:0x2bebb7dc5f4c3b8f!8m2!3d42.7404444!4d20.0740247";
    window.open(mapsUrl, '_blank');
  };


  const villaSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const handleEnquire = () => {
    const phoneNumber = '+38349843699';
    const message = encodeURIComponent('Hello, I would like to inquire about your services.');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  }


  const serviceData = [
    {
      title: "Airport Pick-up Service",
      description: "Experience seamless travel with our dedicated airport pick-up service, ensuring your journey to our haven is as comfortable as your stay.",
      icon: faCarOn
    },
    {
      title: "Exclusive Guided Tours",
      description: "Immerse yourself in the local culture and breathtaking landscapes with our personalized guided tours, tailored to your interests.",
      icon: faCarOn
    },
    {
      title: "In-Room Dining",
      description: "Enjoy a fine dining experience in the comfort of your room with our extensive menu, featuring local and international delicacies.",
      icon: faCarOn
    },
    {
      title: "Spa and Wellness",
      description: "Relax and rejuvenate with our spa services, offering a range of treatments designed to enhance your well-being.",
      icon: faCarOn
    },
    {
      title: "Adventure Activities",
      description: "Explore the great outdoors with our curated adventure activities, perfect for thrill-seekers and nature lovers alike.",
      icon: faCarOn
    },
    {
      title: "Concierge Services",
      description: "Our concierge team is here to assist you with all your needs, from restaurant reservations to event planning.",
      icon: faCarOn
    }
  ];

  const testimonialData = [
    {
      backgroundImage: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/579500689.jpg?k=7551e726cfdb98bc06599ada7e9083df73a06a206fe55f08286a8d346d4b97f8&o=",
      content: t("testimonial1.content"),
      label: t("testimonial1.label"),
    },
    {
      backgroundImage: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/579500689.jpg?k=7551e726cfdb98bc06599ada7e9083df73a06a206fe55f08286a8d346d4b97f8&o=",
      content: t("testimonial2.content"),
      label: t("testimonial2.label"),
    },
    {
      backgroundImage: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/579500689.jpg?k=7551e726cfdb98bc06599ada7e9083df73a06a206fe55f08286a8d346d4b97f8&o=",
      content: t("testimonial3.content"),
      label: t("testimonial3.label"),
    },
  ];


  return (


    <div>
      <Navbar />
      <section className="home_hero">
        <video
          className="home_hero_video"
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          poster={placeholder}
        >

          <source
            src={video1}
            type="video/mp4"
          />
          <img
            src={placeholder}
            alt="Background placeholder"
            className="home_hero_placeholder"
          />
          Your browser does not support the video tag.
        </video>
        <div className="container">
          <div className="home_hero_content">
            <h2>Bujtinat Lisa</h2>
            <p>{t("heroText")}</p>
            <div className="home_hero_button">
              <Button className="main-btn" onClick={handleEnquire} icon="" buttonText={t("contactus")} />
              <Button className="main-btn" onClick={handleDirection} icon="" buttonText={t("direction")} />
            </div>
          </div>
        </div>
      </section>

      <div className="home_hero_checkAvaibility">
        <div className="container">
          <div className="home_hero_checkAvaibility-wrapper">
            <div className="home_hero_checkAvaibility-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 30">
                <path d="M7.11.06c-.22.1-.41.31-.49.52-.04.09-.06.52-.06.95v.78l-1.71.02c-1.68.02-1.73.02-2.17.18C1.45 2.95.6 3.79.17 5L0 5.47v21.42l.16.47c.43 1.21 1.27 2.05 2.5 2.48l.45.16h23.77l.47-.16c1.21-.43 2.05-1.27 2.48-2.5l.16-.45V5.47l-.16-.45c-.43-1.23-1.27-2.07-2.48-2.5-.46-.16-.48-.16-2.18-.18l-1.71-.02-.02-.87C23.42.5 23.39.41 23.02.14c-.23-.17-.78-.17-1.01 0-.38.28-.4.36-.42 1.32l-.02.87H8.44l-.02-.87C8.4.51 8.37.42 8.01.15c-.2-.15-.66-.19-.9-.08Zm-.55 4.78c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.64h13.12l.02.64c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.65 1.59.02 1.6.02.33.16c.42.21.76.55.97.97.16.33.16.34.18 1.7l.02 1.37H1.85V7.18c0-.93.02-1.33.08-1.54.18-.59.69-1.13 1.29-1.33.26-.09.52-.1 1.81-.11h1.5l.02.64Zm21.57 13.63-.02 8.18-.16.32c-.21.42-.57.78-.98.99l-.33.16H3.35l-.32-.16c-.42-.21-.78-.57-.99-.98l-.16-.33-.02-8.18V10.3h26.28v8.17Z"></path>
              </svg>
              <div>
                <h5>{t("checkIn")}</h5>
                <DatePicker
                  selected={checkInDate}
                  onChange={(date) => setCheckInDate(date)}
                  placeholderText="Select Check-in Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="home_hero_checkAvaibility-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 30">
                <path d="M7.11.06c-.22.1-.41.31-.49.52-.04.09-.06.52-.06.95v.78l-1.71.02c-1.68.02-1.73.02-2.17.18C1.45 2.95.6 3.79.17 5L0 5.47v21.42l.16.47c.43 1.21 1.27 2.05 2.5 2.48l.45.16h23.77l.47-.16c1.21-.43 2.05-1.27 2.48-2.5l.16-.45V5.47l-.16-.45c-.43-1.23-1.27-2.07-2.48-2.5-.46-.16-.48-.16-2.18-.18l-1.71-.02-.02-.87C23.42.5 23.39.41 23.02.14c-.23-.17-.78-.17-1.01 0-.38.28-.4.36-.42 1.32l-.02.87H8.44l-.02-.87C8.4.51 8.37.42 8.01.15c-.2-.15-.66-.19-.9-.08Zm-.55 4.78c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.64h13.12l.02.64c.02.69.07.83.42 1.09.23.17.78.17 1.01 0 .35-.26.4-.39.42-1.09l.02-.65 1.59.02 1.6.02.33.16c.42.21.76.55.97.97.16.33.16.34.18 1.7l.02 1.37H1.85V7.18c0-.93.02-1.33.08-1.54.18-.59.69-1.13 1.29-1.33.26-.09.52-.1 1.81-.11h1.5l.02.64Zm21.57 13.63-.02 8.18-.16.32c-.21.42-.57.78-.98.99l-.33.16H3.35l-.32-.16c-.42-.21-.78-.57-.99-.98l-.16-.33-.02-8.18V10.3h26.28v8.17Z"></path>
              </svg>
              <div>
                <h5>{t("checkOut")}</h5>
                <DatePicker
                  selected={checkOutDate}
                  onChange={(date) => setCheckOutDate(date)}
                  placeholderText="Select Check-out Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="home_hero_checkAvaibility-box">
              <a className="main-btn" onClick={checkAvailability}>{t("checkAvailability")}</a>
            </div>
          </div>
        </div>
      </div>
      <section className="home_about">
        <div className="container">
          <div className="home_about-container">
            <div className="home_about-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/566619675.jpg?k=c604b0494745c3a395f780df83f8459f7a943a9dae7108f602d495111649a6d1&o=&hp=1)" }}></div>
            <div className="home_about-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579542437.jpg?k=1222926daa83429a35aa6b1561dc2b557b1e7b68cd2e2c076bda78e5bd14aae2&o=&hp=1)" }}>
              <div className="home_about_overlay2"></div>
              <div className="home_about-box-container">
                <div className="home_about_overlay"></div>
                <p>{t("welcomeMessage")}</p>
                <h1>{t("BUJTINAT LISA, Shkrel")}</h1>
                <a className="link-button" href="/about">
                  {t("exploreHotel")}
                </a>
              </div>
            </div>
            <div className="home_about-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579542738.jpg?k=6b1926de93a27659357f75057a6d13afc50eef181e30afa05b08d429dec3bf1b&o=&hp=1)" }}></div>
          </div>
        </div>
      </section>
      <section className="home_gallery_villa">
        <div className="container ">
          <div className="home_gallery_top">
            <h1>{t("selectVilla")}</h1>
          </div>
        </div>
        <div className="container">
          {villas.length > 0 ? (
            <Slider {...villaSettings} key={villas.length}>
              {villas.map((room, roomIndex) => {
                const language = i18n.language;
                const description = room.description[language] || room.description['en'];
                const name = room.name[language] || room.name['en'];
                const truncatedDescription = description.length > 120
                  ? `${description.substring(0, 120)}...`
                  : description;

                return (

                  <Link to={`/roomDetails/${room.villa_id}`} state={{ villaData: room }} key={roomIndex} className="home_villa_link">
                    <div className="home_villa_box">
                      <div className="home_villa_img">
                        <img src={room.images[0]} alt={room.title} />
                        <div className="room_similar_price">{room.price_per_night}€</div>
                      </div>
                      <div className="home_villa_content">
                        <h2>{name}</h2>
                        <div className="home_villa_info">
                          <div className="home_villa_info-item">
                            <FontAwesomeIcon icon={faExpand} />
                            <span>{room.area_size}</span>
                          </div>
                          <div className="home_villa_info-item">
                            <FontAwesomeIcon icon={faUserGroup} />
                            <span>{room.max_guests}</span>
                          </div>
                          <div className="home_villa_info-item">
                            <FontAwesomeIcon icon={faBed} />
                            <span>{room.number_of_beds}</span>
                          </div>
                        </div>
                        <p>{truncatedDescription}</p>
                        <a href="#">
                          {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
                        </a>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          ) : (
            <p>Loading villas...</p>
          )}
        </div>
      </section>
      <section className="villa_mobile" >
        <img src={homeFav} />
      </section>
      <section className="about_counting" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579510801.jpg?k=fd5b590658e8535f31f6672674ef7631a0a62ad49ea75f28f9cd5ba60f607660&o=)" }}>
        <div className="container">
          <div className="about_counting-wrapper">
            <span>{t("aboutExperience")}</span>
            <h2>{t("aboutExperienceText")}</h2>
            <div className="about_counting-list">
              <div className="about_counting-item">
                <span>8</span>
                <p>{t("rooms")}</p>
              </div>
              <div className="about_counting-item">
                <span>3</span>
                <p>{t("years")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_activity">
        <div className="container">
          <div className="home_activity_wrapper">
            <div className="home_activity_top">
              <h1>{t("unforgettableExperiencesAwait")}</h1>
            </div>
            <div className="home_activity-content">
              <div className="home_activity-box-odd">
                <div className="home_activity-box-container-odd">
                  <img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/579542657.jpg?k=6db7522d1fd9e5da28e155cf1b4253bedd6e727b6da2650dd2174e88fb29f419&o=&hp=1" />
                  <div className="home_activity-box-container-odd-content">
                    <span>{t("outdoorAdventures")}</span>
                    <h1>{t("mainActivityVilla")}</h1>



                    <Link to={`/roomDetails/${villas.find(villa => villa.villa_id === 8)?.villa_id}`} state={{ villaData: villas.find(villa => villa.villa_id === 8) }}>

                      {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="home_activity-box">
                <div className="home_activity-box-hover" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579526121.jpg?k=87391634b3892d3681414901e19e85527175457a2cc065593f84603e4ae4ab47&o=)" }}>
                  <div className="home_activity-box-container">
                    <span>{t("natureEscapes")}</span>
                    <h1>{t("villaRetreat")}</h1>

                    <Link to={`/roomDetails/${villas.find(villa => villa.villa_id === 7)?.villa_id}`} state={{ villaData: villas.find(villa => villa.villa_id === 7) }}>

                      {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="home_activity-box-odd">
                <div className="home_activity-box-container-odd">
                  <img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/580379400.jpg?k=470de32af6adef1eccdf752926e41185a2a3ae91c8a33df3800cebf3ace7c5d3&o=" />
                  <div className="home_activity-box-container-odd-content">
                    <span>{t("outdoor")}</span>
                    <h1>{t("adventureAwaits")}</h1>
                    <Link to={`/roomDetails/${villas.find(villa => villa.villa_id === 1)?.villa_id}`} state={{ villaData: villas.find(villa => villa.villa_id === 1) }}>
                      {t("discoverMore")} <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="home_testimonial">
        <div className="">
          <Slider {...testimonialSettings}>
            {testimonialData.map((testimonial, index) => (
              <div key={index}>
                <div className="testimonial-slide" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HomeImg})` }}>
                  <p>{testimonial.content}</p>
                  <h3>{testimonial.label}</h3>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>

  );
};

export default Home;