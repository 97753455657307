import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../../Assets/logoImg.png"
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import uk from "../../Assets/uk.png";
import al from "../../Assets/albania.png"


const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(localStorage.getItem("i18nextLng") || "en");
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    setActiveLang(lng);
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    if (showNavbar) {
      document.body.classList.add("nav-open");
    } else {
      document.body.classList.remove("nav-open");
    }
  }, [showNavbar]);

  useEffect(() => {
    const savedLang = localStorage.getItem('i18nextLng');
    if (savedLang && savedLang !== i18n.language) {
      i18n.changeLanguage(savedLang)
        .then(() => {
          setActiveLang(savedLang);
        })
        .catch((error) => {
          console.error('Failed to change language:', error);
        });
    }
  }, [i18n]);






  const handleMenuClick = (path) => {
    setActiveMenu(path);
  };

  return (
    <nav className="header">
      <div className="container">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <FontAwesomeIcon icon={showNavbar ? faTimes : faBars} />
        </div>
        <div className={`nav-elements ${showNavbar ? "active" : ""}`}>
          <ul>
            <li className={activeMenu === "/" ? "activeMenu" : ""}>
              <a href="/" onClick={() => handleMenuClick("/")}>
                {t("home")}
              </a>
            </li>
            <li className={activeMenu === "/rooms" ? "activeMenu" : ""}>
              <a href="/rooms" onClick={() => handleMenuClick("/rooms")}>
                {t("rooms")}
              </a>
            </li>
            <li className={activeMenu === "/about" ? "activeMenu" : ""}>
              <a href="/about" onClick={() => handleMenuClick("/about")}>
                {t("about")}
              </a>
            </li>
            <li className={activeMenu === "/blog" ? "activeMenu" : ""}>
              <a href="/blog" onClick={() => handleMenuClick("/blog")}>
                Blog
              </a>
            </li>
            <li className={activeMenu === "/contact" ? "activeMenu" : ""}>
              <a href="tel:+38349843699" onClick={() => handleMenuClick("/contact")}>
                Tel: +38349843699
              </a>

            </li>
            <li style={{ display: 'flex', gap: 2 }}>
              <button
                onClick={() => changeLanguage("al")}
                className={activeLang === "al" ? "activeLang" : ""}
                style={{ padding: 2, backgroundColor: "white", border: "none" }}
              >
                <img style={{width:"15px",height:"15px", marginRight:"7px"}} src={al} alt="Logo" />
                al
              </button>
              <button
                onClick={() => changeLanguage("en")}
                className={activeLang === "en" ? "activeLang" : ""}
                style={{ padding: 2, backgroundColor: "white", border: "none" }}
              >
                  <img style={{width:"15px",height:"15px", marginRight:"7px"}} src={uk} alt="Logo" />
                en
              </button>
             
            </li>
            <li>
              {/* <div className="check-btn">
                <a href="/roomDetails">{t("e")}</a>
              </div> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
