import React, { useEffect, useState } from 'react';
import "./ReservationForm.scss";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";

const BookingForm = ({ villaData, onSubmit, bookedDates }) => {
    const { t, i18n } = useTranslation();
    const [pricePerNight, setPricePerNight] = useState(villaData?.price_per_night || 'Loading...');

    useEffect(() => {
        if (villaData && villaData.price_per_night) {
            setPricePerNight(villaData.price_per_night);
        }
    }, [villaData]);

    const [formData, setFormData] = useState({
        villa_id: villaData?.villa_id || '',
        check_in_date: '',
        check_out_date: '',
        /*  rooms: 1, */
        number_of_adults: 1,
        number_of_children: 0,
        /*  extraServices: {
             roomClean: false,
             massage: { selected: false, quantity: 1 },
             daySpa: { selected: false, quantity: 1 },
         },  */
        guest_name: '',
        guest_email: '',
        guest_contact_number: '',
        total_price: '',
    });

    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        if (formData.check_in_date && formData.check_out_date) {
            const numberOfNights = calculateDaysDifference(formData.check_in_date, formData.check_out_date);

            if (numberOfNights > 0) {
                const cost = numberOfNights * villaData.price_per_night;
                setTotalCost(cost);

                setFormData(prevData => ({
                    ...prevData,
                    total_price: cost
                }));
            } else {
                setTotalCost(0);
                setFormData(prevData => ({
                    ...prevData,
                    total_price: 0
                }));
            }
        }
    }, [formData.check_in_date, formData.check_out_date, villaData.price_per_night]);


    const calculateDaysDifference = (check_in_date, check_out_date) => {
        const check_in_dateDate = new Date(check_in_date);
        const check_out_dateDate = new Date(check_out_date);

        const timeDifference = check_out_dateDate - check_in_dateDate;

        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

        return daysDifference;
    }

    const handleDateChange = (date, field) => {
        const formattedDate = date.toLocaleDateString('en-CA');

        setFormData(prevData => ({
            ...prevData,
            [field]: formattedDate,
        }));
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const newValue = (name === 'number_of_adults' || name === 'number_of_children') ? parseInt(value, 10) : value;

        setFormData(prevData => ({
            ...prevData,
            [name]: newValue
        }));
    };



    const handleExtraServiceChange = (service, value, isQuantity = false) => {
        setFormData((prevData) => {
            const updatedService = isQuantity
                ? { ...prevData.extraServices[service], quantity: value }
                : { selected: value, quantity: 1 };
            return {
                ...prevData,
                extraServices: {
                    ...prevData.extraServices,
                    [service]: updatedService,
                },
            };
        });
    };

    const calculateTotalCost = () => {
        let cost = 299; // base cost per night

        // Commenting out extra services cost calculation
        /*
        if (formData.extraServices.roomClean.selected) cost += 12;
        if (formData.extraServices.massage.selected)
            cost += 30 * formData.extraServices.massage.quantity;
        if (formData.extraServices.daySpa.selected)
            cost += 45 * formData.extraServices.daySpa.quantity;
        */

        setTotalCost(cost);
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        onSubmit(formData, () => {
            // Reset the form after a successful submission
            setFormData({
                villa_id: villaData?.villa_id || '',
                check_in_date: '',
                check_out_date: '',
                number_of_adults: 1,
                number_of_children: 0,
                guest_name: '',
                guest_email: '',
                guest_contact_number: '',
                total_price: '',
            });

            setTotalCost(0);
        });
    };



    return (
        <div className="booking-form">
            <div className='booking-title'>
                <h2>{t('reserve')}</h2>
                <p>{t('from')} <strong>€{pricePerNight}</strong> /{t('night')}</p>
            </div>

            <form onSubmit={handleSubmit}>
                <DatePicker
                    selected={formData.check_in_date}
                    onChange={(date) => handleDateChange(date, 'check_in_date')}
                    excludeDates={bookedDates} // Disable booked dates
                    placeholderText="Select a check-in date"
                    className="custom-datepicker" // Custom class for the input
                    wrapperClassName="custom-datepicker-wrapper" // Custom class for the wrapper
                />
                <DatePicker
                    selected={formData.check_out_date}
                    onChange={(date) => handleDateChange(date, 'check_out_date')}
                    excludeDates={bookedDates} // Disable booked dates
                    placeholderText="Select a check-out date"
                    className="custom-datepicker" // Custom class for the input
                    wrapperClassName="custom-datepicker-wrapper" // Custom class for the wrapper
                />
                <div className="booking-person">
                    <div>
                        <label>{t('adults')}</label>
                        <input
                            type="number"
                            name="number_of_adults"
                            value={formData.number_of_adults}
                            onChange={handleInputChange}
                            min="1" // Ensure at least 1 adult is required
                            max="20" // Optional: Set a reasonable max number of adults
                        />
                    </div>
                    <div>
                        <label>{t('children')}</label>
                        <input
                            type="number"
                            name="number_of_children"
                            value={formData.number_of_children}
                            onChange={handleInputChange}
                            min="0" // Ensure a minimum of 0 children
                            max="20" // Optional: Set a reasonable max number of children
                        />
                    </div>
                </div>


                <div>
                    <label>{t('fullName')}</label>
                    <input
                        type="text"
                        name="guest_name"
                        value={formData.guest_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>{t('email')}</label>
                    <input
                        type="email"
                        name="guest_email"
                        value={formData.guest_email}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>{t('contactNr')}</label>
                    <input
                        type="text"
                        name="guest_contact_number"
                        value={formData.guest_contact_number}
                        onChange={handleInputChange}
                    />
                </div>




                {/* Commenting out Extra Services Section */}
                {/*
            <h3>Extra Services</h3>
            <div className='booking-services'>
                <div>
                    <input
                        type="checkbox"
                        checked={formData.extraServices.roomClean.selected}
                        onChange={(e) => handleExtraServiceChange('roomClean', e.target.checked)}
                    />
                   <label>Room Clean</label>
                   <p>$12 / Night</p>
                </div>
                <div>
                    <input
                        type="checkbox"
                        checked={formData.extraServices.massage.selected}
                        onChange={(e) => handleExtraServiceChange('massage', e.target.checked)}
                    />
                   <label>Massage</label>
                   <p>$30 / Person</p>
                   <select
                        value={formData.extraServices.massage.quantity}
                        onChange={(e) => handleExtraServiceChange('massage', e.target.value, true)}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
            </div>
            */}

                <div className='booking-bottom'>
                    <h3>{t('totalCost')}:</h3>
                    <span>€{totalCost}</span>
                </div>

                {/*<button onClick={calculateTotalCost}>Book Your Stay Now</button>*/}
                <button className='submitReservation' type="submit">{t('submit')}</button>
            </form>
        </div>
    );
};

export default BookingForm;
